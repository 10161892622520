import { Dispatch, SetStateAction } from "react";
import { InitState, InitStatus, InitUserRole, UUID } from "gx-npm-lib";

export type Product = {
  productName: string;
  imageLoc: string;
};

export type Initiative = {
  products: Product[];
  id: UUID;
  name: string;
  templateName: string;
  status: InitStatus;
  stateUpdateDate: string;
  userRole: InitUserRole;
  lastActivity: LastActivity;
};

export type LastActivity = {
  firstName: string;
  lastName: string;
  accessDate: string;
};

export type DashBoardAppContextValue = {
  bulkDeleteIds: UUID[];
  setBulkDeleteIds: Dispatch<SetStateAction<UUID[]>>;
  bulkDeleteCount: number;
  setBulkDeleteCount: Dispatch<SetStateAction<number>>;
  userPermissionsIsLoading: boolean;
  setUserPermissionsIsLoading: Dispatch<SetStateAction<boolean>>;
  userPermissionsLoadError: boolean;
  setUserPermissionsLoadError: Dispatch<SetStateAction<boolean>>;
  userInitiatives: Initiative[] | [];
  setUserInitiatives: Dispatch<SetStateAction<Initiative[]>>;
  currentState: InitState;
  setCurrentState: Dispatch<SetStateAction<InitState>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  showSnackBar: boolean;
  setShowSnackBar: Dispatch<SetStateAction<boolean>>;
  snackbarMessage: string;
  setSnackbarMessage: Dispatch<SetStateAction<string>>;
  updatedInitiativeName: string;
  setUpdatedInitiativeName: Dispatch<SetStateAction<string>>;
  updateStateProcessing: boolean;
  setUpdateStateProcessing: Dispatch<SetStateAction<boolean>>;
  loadDataErrorStatus: boolean;
  setLoadDataErrorStatus: Dispatch<SetStateAction<boolean>>;
  saveDataErrorStatus: boolean;
  setSaveDataErrorStatus: Dispatch<SetStateAction<boolean>>;
  userInitiativesOwnerCount: number;
  setUserInitiativesOwnerCount: Dispatch<SetStateAction<number>>;
};

export enum InitiativeStateChangeAction {
  RESTORE = "RESTORE",
  ARCHIVE = "ARCHIVE",
  DELETE = "DELETE",
  PERMANENTLY_DELETE = "PERMANENTLY_DELETE",
}
