import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as singleSpa from "single-spa";
import { makeStyles } from "@material-ui/core";
import {
  Button,
  Header,
  Paragraph,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { evalNotStartedViewStyles as styles } from "./eval-not-started-view.styles";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flags";

const propTypes = {
  hasWarningBanner: PropTypes.bool,
  imgHeight: PropTypes.number,
  imgSrc: PropTypes.string,
  isDisplayingLink: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const EvalNotStartedView = ({
  hasWarningBanner = false,
  imgHeight = 0,
  imgSrc = "",
  isDisplayingLink = false,
  title = "",
  subtitle = "",
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classNames("evel-not-started", classes.container, hasWarningBanner && classes.hasWarningBanner)}>
      <div>
        <img alt={t("No records")} height={imgHeight} src={imgSrc} />
      </div>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent rootClassName={classes.titleGCOM3606} boldness={"medium"} styling={"h4"} color={"iron"}>
            {title}
          </TypographyComponent>
          <TypographyComponent rootClassName={classes.subTitle} color={"iron"} styling={"p3"} boldness={"regular"}>
            {t(subtitle)}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <Header type="h5" rootClassName={classes.title}>
            {title}
          </Header>
          <Paragraph rootClassName={classes.subTitle} boldness="regular" type="p2">
            {t(subtitle)}
          </Paragraph>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>

      {isDisplayingLink && (
        <Button onClick={() => singleSpa.navigateToUrl("/s/markets")} rootClassName="btn-tertiary">
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <div>
                <TypographyComponent styling={"p3"} color={"defaultCta"} boldness={"semi"}>
                  {t("BROWSE MARKETS")}
                </TypographyComponent>
              </div>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <div className={classes.browseEval}>{t("BROWSE TEMPLATES")}</div>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </Button>
      )}
    </div>
  );
};

EvalNotStartedView.propTypes = propTypes;
export default EvalNotStartedView;
