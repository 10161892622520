import React, { Fragment, useState, useEffect } from "react";
import { handleEvent } from "gx-npm-lib";
import {
  Button,
  ButtonLoader,
  Dialog,
  TextField,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";
import styles from "./action-dialog.module.scss";

type ActionDialogProps = {
  confirmTextValue?: string;
  displayConfirmTextbox?: boolean;
  displayName?: string;
  handleClose?: () => void;
  handleConfirm?: () => void;
  isLoading?: boolean;
  isOpen?: boolean;
  textBodyEnd?: string;
  textBodyStart?: string;
  textButtonCancel?: string;
  textButtonConfirm?: string;
  textConfirmLabel?: string;
  textTitle?: string;
};

const ActionDialog = ({
  confirmTextValue = "",
  displayConfirmTextbox = false,
  displayName = "",
  handleClose = () => {},
  handleConfirm = () => {},
  isLoading = false,
  isOpen = false,
  textBodyEnd = "",
  textBodyStart = "",
  textButtonCancel = "",
  textButtonConfirm = "",
  textConfirmLabel = "",
  textTitle = "",
}: ActionDialogProps) => {
  const [confirmText, setConfirmText] = useState("");
  const [confirmDisabled, setConfirmDisabled] = useState(displayConfirmTextbox);

  const handleTextboxChange = (e: { target: { value: string } }) => {
    handleEvent(setConfirmText, e.target.value);
  };
  useEffect(() => {
    const value =
      displayConfirmTextbox &&
      !(confirmText && confirmTextValue && confirmText.toLowerCase() === confirmTextValue.toLowerCase());
    setConfirmDisabled(value);
  }, [confirmText, confirmTextValue, displayConfirmTextbox]);

  const closeHandler = () => {
    setConfirmDisabled(displayConfirmTextbox);
    setConfirmText("");
    handleEvent(handleClose);
  };

  return (
    <Dialog
      open={isOpen}
      handleClose={closeHandler}
      title={textTitle}
      body={
        <Fragment>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent element={"span"} rootClassName={styles.dialogText}>
                <TypographyComponent element={"span"} styling={"p2"} color={"coal"}>
                  {textBodyStart}
                </TypographyComponent>
                <TypographyComponent
                  element={"span"}
                  styling={"p2"}
                  boldness={"medium"}
                  color={"coal"}
                >{` ${displayName}`}</TypographyComponent>
                <TypographyComponent element={"span"} styling={"p2"} color={"coal"}>
                  {textBodyEnd}
                </TypographyComponent>
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <p style={{ marginBottom: 20 }}>
                <span>{textBodyStart}</span>
                <span className="semi-bold">{` ${displayName}`}</span>
                <span>{textBodyEnd}</span>
              </p>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>

          {displayConfirmTextbox && (
            <TextField fullWidth label={textConfirmLabel} onChange={handleTextboxChange} value={confirmText} />
          )}
        </Fragment>
      }
      footer={
        <div style={{ display: "flex", float: "right" }}>
          {!!handleClose && (
            <Button onClick={closeHandler} rootClassName="btn-tertiary">
              {textButtonCancel}
            </Button>
          )}
          {!!handleConfirm && (
            <div style={{ marginLeft: 24 }}>
              <ButtonLoader
                btnClass={confirmDisabled ? "btn-primary" : "primary-destructive-btn"}
                disabled={confirmDisabled}
                handleButtonClick={() => handleEvent(handleConfirm)}
                isLoading={isLoading}
              >
                {textButtonConfirm}
              </ButtonLoader>
            </div>
          )}
        </div>
      }
    />
  );
};

export default ActionDialog;
