import locale from "date-fns/locale/en-US";
import { formatDistanceToNowStrict } from "date-fns";

import { isValidDate } from "../isUtils";

type FormatDistanceLocale = {
  [key: string]: string;
  lessThanXSeconds: string;
  xSeconds: string;
  halfAMinute: string;
  lessThanXMinutes: string;
  xMinutes: string;
  aboutXHours: string;
  xHours: string;
  xDays: string;
  aboutXWeeks: string;
  xWeeks: string;
  aboutXMonths: string;
  xMonths: string;
  aboutXYears: string;
  xYears: string;
  overXYears: string;
  almostXYears: string;
};

const timeFromDateFormat = (date: string): string => {
  if (!isValidDate(date)) {
    return "-";
  }

  const formatDistanceLocale: FormatDistanceLocale = {
    lessThanXSeconds: "1m",
    xSeconds: "1m",
    halfAMinute: "1m",
    lessThanXMinutes: "{{count}}m",
    xMinutes: "{{count}}m",
    aboutXHours: "{{count}}h",
    xHours: "{{count}}h",
    xDays: "{{count}}d",
    aboutXWeeks: "{{count}}wk",
    xWeeks: "{{count}}wk",
    aboutXMonths: "{{count}}mo",
    xMonths: "{{count}}mo",
    aboutXYears: "{{count}}yr",
    xYears: "{{count}}yr",
    overXYears: "{{count}}yr",
    almostXYears: "{{count}}yr",
  };

  const timeFromDate = new Date(date);

  function formatDistance(token: string, count: number) {
    const result = formatDistanceLocale[token].replace("{{count}}", String(count));
    return result + " ago";
  }

  return formatDistanceToNowStrict(timeFromDate, {
    addSuffix: true,
    locale: {
      ...locale,
      formatDistance,
    },
  });
};

export default timeFromDateFormat;
