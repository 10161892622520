import React, { Fragment, useContext, useEffect, useState } from "react";
import { InitState } from "gx-npm-lib";
import noActiveRecords from "../../assets/noActiveRecords.svg";
import noArchivedRecords from "../../assets/noArchivedRecords.svg";
import noTrashRecords from "../../assets/noTrashRecords.svg";
import { DashBoardAppContext } from "../../app.context";
import { useTranslation } from "react-i18next";
import EvalNotStartedView from "./eval-not-started-view/eval-not-started-view.component";
import { alertButtonTypes, alertLevels, InlineAlert } from "gx-npm-ui";

const EvalNotStarted = () => {
  const { currentState, userInitiativesOwnerCount } = useContext(DashBoardAppContext);
  const { t } = useTranslation();
  const [showWarning, setShowWarning] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (userInitiativesOwnerCount === 0) {
      return;
    }
    const userOwnedInitCount = userInitiativesOwnerCount;
    const isInitStateBetween90And100 = userOwnedInitCount >= 90 && userOwnedInitCount < 100;
    const isInitStateGreaterThanOrEqualTo100 = userOwnedInitCount >= 100;
    setShowWarning(isInitStateBetween90And100);
    setShowError(isInitStateGreaterThanOrEqualTo100);
  }, [userInitiativesOwnerCount]);

  return (
    <Fragment>
      <div style={{ marginBottom: 15 }}>
        {showWarning && (
          <InlineAlert
            alertType={alertLevels.WARNING}
            isShadowed={true}
            textMessage={t(
              "You are nearing the max number of evaluations (100). Delete unneeded evaluations in the Active tab, then permanently delete them from the Trash tab to free up capacity."
            )}
          />
        )}
        {showError && (
          <InlineAlert
            buttonType={alertButtonTypes.CLOSE}
            alertType={alertLevels.ERROR}
            isShadowed={true}
            textMessage={t(
              "You've reached the max number of evaluations (100). Delete unneeded evaluations in the Active tab, then permanently delete them from the Trash tab to free up capacity."
            )}
          />
        )}
      </div>
      {currentState === InitState.ACTIVE && (
        <EvalNotStartedView
          hasWarningBanner={showError || showWarning}
          imgHeight={203}
          imgSrc={noActiveRecords}
          isDisplayingLink={true}
          subtitle={t(`Get started with our premade templates so we can guide you through this process.`)}
          title={t("You don't have any active evaluations.")}
        />
      )}
      {currentState === InitState.ARCHIVED && (
        <EvalNotStartedView
          hasWarningBanner={showError || showWarning}
          imgHeight={147}
          imgSrc={noArchivedRecords}
          isDisplayingLink={false}
          subtitle={"Archived evaluations can always be restored."}
          title={t("You don't have any archived evaluations.")}
        />
      )}
      {currentState === InitState.DELETED && (
        <EvalNotStartedView
          hasWarningBanner={showError || showWarning}
          imgHeight={147}
          imgSrc={noTrashRecords}
          isDisplayingLink={false}
          subtitle={"Deleted evaluations can be restored or permanently deleted."}
          title={t("You don't have any deleted evaluations.")}
        />
      )}
    </Fragment>
  );
};

export default EvalNotStarted;
