import { Fade } from "@material-ui/core";
import classNames from "classnames";
import { FreeTrialRole, InitState, InitUserRole, useUserState, UUID } from "gx-npm-lib";
import { alertButtonTypes, alertLevels, Button, InlineAlert } from "gx-npm-ui";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as singleSpa from "single-spa";
import { isArchiveOrDeleteState } from "../../lib";
import { DashBoardAppContext } from "../../app.context";
import EvalCardItemV2 from "../eval-card-item/eval-card-item-v2.component";
import BulkDeleteInitiativeBanner from "./bulk-delete-initiative-banner/bulk-delete-initiative-banner.component";
import styles from "./eval-cards.module.scss";

const EvalCards = () => {
  const { t } = useTranslation();
  const [showWarning, setShowWarning] = useState(false);
  const [showError, setShowError] = useState(false);
  const [collapseInitId, setCollapseInitId] = useState<UUID>("");
  const [fadeInitId, setFadeInitId] = useState<UUID>("");
  const { userInitiativesOwnerCount } = useContext(DashBoardAppContext);
  const { freeTrialRole } = useUserState();
  const isTrialUser = freeTrialRole === FreeTrialRole.FREE_TRIAL_RECIPIENT;

  const collapsingTimer = 300;
  const cleanupTimer = 600;

  useEffect(() => {
    if (userInitiativesOwnerCount === 0) {
      return;
    }
    const userOwnedInitCount = userInitiativesOwnerCount;
    const isInitStateBetween90And100 = userOwnedInitCount >= 90 && userOwnedInitCount < 100;
    const isInitStateGreaterThanOrEqualTo100 = userOwnedInitCount >= 100;
    setShowWarning(isInitStateBetween90And100);
    setShowError(isInitStateGreaterThanOrEqualTo100);
  }, [userInitiativesOwnerCount]);

  const { userInitiatives, currentState, setBulkDeleteIds, bulkDeleteIds, setUserInitiatives } =
    useContext(DashBoardAppContext);

  useEffect(() => {
    if (currentState === InitState.DELETED) {
      const bulkDeleteData = userInitiatives
        .filter(({ userRole }) => userRole === InitUserRole.OWNER)
        .map(({ id }) => id);
      setBulkDeleteIds(bulkDeleteData);
    }
  }, [userInitiatives, currentState, setBulkDeleteIds]);
  const hideLink = isArchiveOrDeleteState(currentState);
  const hasInitiativesWhereUserIsOwner = bulkDeleteIds.length !== 0;

  const handleRemoveAnimation = (initId: UUID) => {
    // Fade out the initiative, then collapse it, then remove it from the list
    setFadeInitId(initId);

    setTimeout(() => {
      setCollapseInitId(initId);

      setTimeout(() => {
        setUserInitiatives((prev) => prev.reduce((acc, curr) => (curr.id === initId ? acc : [...acc, curr]), []));
        setFadeInitId("");
        setCollapseInitId("");
      }, cleanupTimer);
    }, collapsingTimer);
  };

  return (
    <div className={styles.container}>
      <div className={styles.alerts}>
        {showWarning && (
          <InlineAlert
            alertType={alertLevels.WARNING}
            isShadowed={true}
            textMessage={t(
              "You are nearing the max number of evaluations (100). Delete unneeded evaluations in the Active tab, then permanently delete them from the Trash tab to free up capacity."
            )}
          />
        )}
        {showError && (
          <InlineAlert
            buttonType={alertButtonTypes.CLOSE}
            alertType={alertLevels.ERROR}
            isShadowed={true}
            textMessage={t(
              "You've reached the max number of evaluations (100). Delete unneeded evaluations in the Active tab, then permanently delete them from the Trash tab to free up capacity."
            )}
          />
        )}
        {isTrialUser && userInitiatives.length === 2 && (
          <InlineAlert
            alertType={alertLevels.WARNING}
            isShadowed={true}
            textMessage={t(
              "You are nearing the max number of evaluations (3) provided in your Gartner BuySmart free trial."
            )}
          />
        )}
        {isTrialUser && userInitiatives.length > 2 && (
          <InlineAlert
            alertType={alertLevels.ERROR}
            isShadowed={true}
            textMessage={t(
              "You’ve hit the max number of evaluations (3) provided in your Gartner BuySmart free trial."
            )}
          />
        )}
      </div>
      {currentState === InitState.DELETED && hasInitiativesWhereUserIsOwner && <BulkDeleteInitiativeBanner />}
      {userInitiatives.map((initiative, index) => (
        <div
          key={initiative.id}
          className={classNames(styles.initiative, collapseInitId === initiative.id && styles.removing)}
        >
          <Fade in={fadeInitId !== initiative.id}>
            <div>
              <EvalCardItemV2
                onRemoveInitiative={handleRemoveAnimation}
                data={initiative}
                isLastCard={index === userInitiatives.length - 1}
              />
            </div>
          </Fade>
        </div>
      ))}
      {!hideLink && (
        <Button onClick={() => singleSpa.navigateToUrl("/s/markets")} rootClassName="btn-tertiary">
          {t("BROWSE MARKETS")}
        </Button>
      )}
    </div>
  );
};

export default EvalCards;
