import { InitState } from "gx-npm-lib";
import { InitiativeStateChangeAction } from "../app.types";
import { t } from "i18next";

const getInitiativeStateLabels = () => {
  return [
    { label: t("Active"), value: InitState.ACTIVE },
    { label: t("Archived"), value: InitState.ARCHIVED },
    { label: t("Trash"), value: InitState.DELETED },
  ];
};

const getMenuItemByState = (state: InitState) => {
  const restoreMenuItem = {
    index: 0,
    name: t("Restore"),
    action: InitiativeStateChangeAction.RESTORE,
  };
  const deleteMenuItem = {
    index: 1,
    name: t("Delete"),
    action: InitiativeStateChangeAction.DELETE,
  };
  const archiveMenuItem = {
    index: 2,
    name: t("Archive"),
    action: InitiativeStateChangeAction.ARCHIVE,
  };
  const permanentlyDeleteMenuItem = {
    index: 3,
    name: t("Permanently Delete"),
    action: InitiativeStateChangeAction.PERMANENTLY_DELETE,
    rootClassName: "gx-menu-item-permanent-delete",
  };
  if (state === InitState.ACTIVE) {
    return [archiveMenuItem, deleteMenuItem];
  } else if (state === InitState.ARCHIVED) {
    return [restoreMenuItem, deleteMenuItem];
  } else if (state === InitState.DELETED) {
    return [restoreMenuItem, archiveMenuItem, permanentlyDeleteMenuItem];
  }
  return [];
};

const isArchiveOrDeleteState = (currentState: InitState) => {
  return [InitState.ARCHIVED, InitState.DELETED].includes(currentState);
};

const getStateLabel = (state: InitState) => {
  for (const item of getInitiativeStateLabels()) {
    if (item.value === state) {
      return item.label;
    }
  }
  return "";
};

export { getInitiativeStateLabels, getStateLabel, isArchiveOrDeleteState, getMenuItemByState };
