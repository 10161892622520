import { colorPalette } from "gx-npm-common-styles";
const deleteBannerStyles = {
  root: {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    justifyContent: "center",
    marginBottom: 24,
    height: 64,
    backgroundColor: `${colorPalette.neutrals.silver.hex}B3`,
  },
  text: {
    marginRight: 21,
    color: colorPalette.neutrals.coal.hex,
  },
  textGCOM3606: {
    marginRight: 21,
  },
};

export { deleteBannerStyles };
