const HeaderStyle = {
  container: {
    alignItems: "center",
    display: "flex",
    margin: "0 auto",
    maxWidth: "1512px",
    padding: "20px 24px 32px 24px",
    transition: "padding 0.5s",

    "@media (min-width:1240px)": {
      padding: "20px 56px 32px 56px",
    },
  },
  stateLabel: {
    flex: 1,
    paddingLeft: "8px",
    textTransform: "capitalize" as const,
  },
};

export { HeaderStyle };
