import axios from "axios";
import { defaultResponse, mergeConfigs } from "./apiRequestUtils";

/**
 * DELETE request
 * @param {string} url - api request url (including any query strings)
 * @param {object} additionalConfig - any additional configurations for requests
 * @returns { data, error } object with data from API in data key, and any error in error key
 */
const deleteAsyncRequest = async (url = "", additionalConfig = {}) => {
  const config = mergeConfigs(additionalConfig);
  const response = { ...defaultResponse };
  try {
    const axiosResponse = await axios.delete(url, config);
    if (axiosResponse) {
      const { data, status, statusText } = axiosResponse;
      response.data = data;
      response.status = status;
      response.statusText = statusText;
    }
  } catch (error) {
    if (error.response) {
      response.data = error.response.data;
      response.status = error.response.status;
    } else {
      response.data = error;
    }
  }
  return response;
};

export default deleteAsyncRequest;
