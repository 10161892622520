import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { StylesProvider, createGenerateClassName } from "@material-ui/core/styles";
import { commonTheme } from "gx-npm-common-styles";
import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DashBoardAppContextProvider } from "./app.context";
import AppV3Component from "./app-v3.component";
import HandleRedirectsComponent from "./handle-redirects.component";

const muiTheme = createMuiTheme(commonTheme);
const generateClassName = createGenerateClassName({ seed: "DashboardApp" });
export default function Root() {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <ThemeProvider theme={muiTheme}>
        <StylesProvider generateClassName={generateClassName}>
          <DashBoardAppContextProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/s/evaluations" element={<AppV3Component />} />
                <Route path="*" element={<HandleRedirectsComponent />} />
              </Routes>
            </BrowserRouter>
          </DashBoardAppContextProvider>
        </StylesProvider>
      </ThemeProvider>
    </FeatureFlagProvider>
  );
}
