import { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { navigateToUrl } from "single-spa";

const HandleRedirectsComponent = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/s/dashboard")) {
      navigateToUrl("/s/evaluations");
    }
  }, [location]);

  return <Fragment />;
};

export default HandleRedirectsComponent;
