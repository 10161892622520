import React, { createContext, useState, ReactNode } from "react";
import { InitState, UUID } from "gx-npm-lib";
import { DashBoardAppContextValue, Initiative } from "./app.types";

const DashBoardAppContext = createContext<DashBoardAppContextValue>({} as DashBoardAppContextValue);
const DashBoardAppContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [currentState, setCurrentState] = useState<InitState>(InitState.ACTIVE);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [updatedInitiativeName, setUpdatedInitiativeName] = useState<string>("");
  const [userInitiatives, setUserInitiatives] = useState<Initiative[]>([]);
  const [updateStateProcessing, setUpdateStateProcessing] = useState<boolean>(false);
  const [loadDataErrorStatus, setLoadDataErrorStatus] = useState<boolean>(false);
  const [userPermissionsIsLoading, setUserPermissionsIsLoading] = useState<boolean>(true);
  const [userPermissionsLoadError, setUserPermissionsLoadError] = useState<boolean>(false);
  const [bulkDeleteCount, setBulkDeleteCount] = useState<number>(0);
  const [bulkDeleteIds, setBulkDeleteIds] = useState<UUID[]>([]);
  const [saveDataErrorStatus, setSaveDataErrorStatus] = useState<boolean>(false);
  const [userInitiativesOwnerCount, setUserInitiativesOwnerCount] = useState<number>(0);
  const contextValue = {
    currentState,
    setCurrentState,
    isLoading,
    setIsLoading,
    showSnackBar,
    setShowSnackBar,
    snackbarMessage,
    setSnackbarMessage,
    updatedInitiativeName,
    setUpdatedInitiativeName,
    userInitiatives,
    setUserInitiatives,
    updateStateProcessing,
    setUpdateStateProcessing,
    loadDataErrorStatus,
    setLoadDataErrorStatus,
    bulkDeleteIds,
    setBulkDeleteIds,
    bulkDeleteCount,
    setBulkDeleteCount,
    userPermissionsIsLoading,
    setUserPermissionsIsLoading,
    userPermissionsLoadError,
    setUserPermissionsLoadError,
    saveDataErrorStatus,
    setSaveDataErrorStatus,
    userInitiativesOwnerCount,
    setUserInitiativesOwnerCount,
  };

  return <DashBoardAppContext.Provider value={contextValue}>{props.children}</DashBoardAppContext.Provider>;
};

export { DashBoardAppContext, DashBoardAppContextProvider };
